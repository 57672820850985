import React from "react";

export default function Tool({ tool }) {
  const link = tool.website || tool.source;

  return (
    <div
      style={{
        marginBottom: 20,
        paddingBottom: 20,
        borderBottom: "1px solid #ececec",
      }}
    >
      <h3 style={{ marginTop: 0, marginBottom: 8, fontSize: 18 }}>
        <a href={`https://westack.live/tools/${tool.key}`}>{tool.name}</a>
      </h3>
      <a
        href={link}
        style={{
          display: "block",
          marginBottom: 8,
          textDecoration: "none",
          fontWeight: "normal",
          color: "#666666",
        }}
        className="tool-link"
      >
        🔗 原链 {new URL(link).host}
      </a>
      <div>{tool.about}</div>
    </div>
  );
}
