import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/weekly/weekly/packages/website/src/components/Layout.js";
import { graphql } from "gatsby";
export const pageQuery = graphql`
  fragment ToolInfo on WESTACK_Tool {
    about
    id
    key
    name
    source
    website
    link
    iconURL(size: 200)
  }
  {
    ERPNext: westack {
      tool(id: "erpnext") {
        ...ToolInfo
      }
    }
    Tauri: westack {
      tool(id: "tauri") {
        ...ToolInfo
      }
    }
    Focalboard: westack {
      tool(id: "focalboard") {
        ...ToolInfo
      }
    }
    macOCR: westack {
      tool(id: "macocr") {
        ...ToolInfo
      }
    }
    XVIZ: westack {
      tool(id: "xviz") {
        ...ToolInfo
      }
    }
    Zellij: westack {
      tool(id: "zellij") {
        ...ToolInfo
      }
    }
    Vercel: westack {
      tool(id: "vercel") {
        ...ToolInfo
      }
    }
    Gitpod: westack {
      tool(id: "gitpod") {
        ...ToolInfo
      }
    }
    Winbox: westack {
      tool(id: "winbox") {
        ...ToolInfo
      }
    }
    Stack_on_a_budget: westack {
      tool(id: "stack-on-a-budget") {
        ...ToolInfo
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tool = makeShortcode("Tool");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{props.pageContext.frontmatter.title}</h1>
    <Tool tool={props.data.ERPNext.tool} mdxType="Tool" />
    <Tool tool={props.data.Tauri.tool} mdxType="Tool" />
    <Tool tool={props.data.Focalboard.tool} mdxType="Tool" />
    <Tool tool={props.data.macOCR.tool} mdxType="Tool" />
    <Tool tool={props.data.XVIZ.tool} mdxType="Tool" />
    <Tool tool={props.data.Zellij.tool} mdxType="Tool" />
    <Tool tool={props.data.Vercel.tool} mdxType="Tool" />
    <Tool tool={props.data.Gitpod.tool} mdxType="Tool" />
    <Tool tool={props.data.Winbox.tool} mdxType="Tool" />
    <Tool tool={props.data.Stack_on_a_budget.tool} mdxType="Tool" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      