import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Helmet } from "react-helmet";
import Tool from "./Tool";
import SubscribeForm from "./SubscribeForm";
import Footer from "./Footer";

const shortcodes = { Tool };

// TODO: fix title
export default function Layout({ children }) {
  return (
    <MDXProvider components={shortcodes}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WeStack 周报</title>
      </Helmet>
      <div className="main">
        <div className="head">
          <a href="/">
            <img
              alt="WeStack logo"
              src="https://westack.live/logo-180.png"
              width="100"
              height="100"
            />
          </a>
        </div>

        <div className="issue">{children}</div>
        <SubscribeForm />
        <Footer />
      </div>
    </MDXProvider>
  );
}
